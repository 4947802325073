<template>
  <div class="bgcard">
    <div>
      <!-- <div class="headerMsg">
        <div class="searchlist">
					<div class="query">
						<div class="inputname">
							<input v-model="value" :placeholder="$t('lang.Pleaseentertheadministratorname')" />
						</div>
						<div class="searchbtn">
							<button @click="search">
								<p>{{$t('lang.search')}}</p>
							</button>
						</div>
					</div>
        </div>
      </div> -->
      <div class="serverfroms">
        <el-table :data="tableData.data" style="width: 100%">
          <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
          <el-table-column prop="name" :label="$t('lang.equipmentName')" align="center">
          </el-table-column>
          <el-table-column prop="modelName" :label="$t('lang.model')" align="center">
          </el-table-column>
          <el-table-column :label="$t('lang.User')" align="center">
            <template #default="scope">
              <span v-if="scope.row.state == 0">/</span>
              <span v-else>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lang.Operationtype')" align="center">
          <template #default="scope">
            <span>{{scope.row.type == 2 ?   $t("lang.lease") : scope.row.type == 3 ? $t("lang.sale"): ''}}</span>
          </template>
        </el-table-column>
          <el-table-column :label="$t('lang.Devicestatus')" align="center">
          	<template #default="scope">
          		<span v-if="scope.row.status ==0">{{$t('lang.offline')}}</span>
          		<span v-else>{{$t('lang.onLine')}}</span>
          	</template>
          </el-table-column>
          <el-table-column :label="$t('lang.workingMode')" align="center">
          	<template #default="scope">
          		<span v-if="scope.row.deviceDefaults.workMode ==0">{{$t('lang.livebroadcast')}}</span>
          		<span v-if="scope.row.deviceDefaults.workMode ==1">{{$t('lang.Losslessrecordingandbroadcasting')}}</span>
          		<span v-if="scope.row.deviceDefaults.workMode ==2">{{$t('lang.filetransfer')}}</span>
          	</template>
          </el-table-column>
        </el-table>
				<!-- <el-pagination background v-if="tableData" layout="prev, pager, next" :total="tableData.total" @current-change="handlePageCurrentChange" :prev-text='$t("lang.previouspage")' :next-text='$t("lang.nextpage")'>
				</el-pagination> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as user from "@/api/apis.js";
export default {
  data() {
    return {
			value:'',
			parsm:{
				email:this.$route.query.email
			},
			tableData:''
    };
  },
	mounted() {
		this.getList()
	},
  methods: {
		search(){
			console.log('kk')
		},
		async getList(){
			let res=await user.userDevices(this.parsm)
			console.log(res)
			if(res){
				this.tableData=res
			}
		}
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.headerMsg {
  display: flex;
  justify-content: flex-end;
  .addbtn {
    button {
      height: 0.5rem;
      border: none;
      outline-style: none;
      padding: 0px 0.12rem;
      background: #307b37;
      border-radius: 0.1rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.buttons {
		padding: 0px;
		width: 0.82rem;
		height: 0.42rem;
		outline-style: none;
		border: none;
		border-radius: 0.04rem;
		font-size: 0.16rem;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
}
</style>
